const messages = {
    // english
    en: {
        message: {
            // header
            home: 'Home',
            product: 'Products',
            aboutus: `About US`,
            contactus: 'Contact US',
            article: 'Article',
            shoppingcart: 'Shopping Cart',
            search: 'Search',
            requestAQuota: ' Request A Quota',

            //register
            registerTitle: 'New Customer Register Here',
            requiredInfo: "Required Information",
            firstName: "First Name",
            lastName: "Last Name",
            address: "Address",
            password: "Password",
            confirmPassword: "Confirm Password",

            // login
            forgetPassword: "Forgot Password?",
            changePassword: "Change Password",
            updatePassword: "Update Password",


            //contact us
            formMsg: "Please fill in the following fields.",
            company: "Company",
            fullName: "Full Name",
            email: "Email",
            phone: "Phone",
            department: "Department",
            orderNo: "Order No",
            msg: "Message",
            submit: "Submit",
            reset: "Reset",
            update: "Update",

            //product
            priceCalculator: "Price Calculator",
            size: "Size (inches)",
            quantity: "Quantity",
            material: "Material",
            printingType: "Printing Type",
            printingSides: "Printing Sides",
            biType: "Bi Type",
            sheet: "Sheet",
            type: "Type",
            ratioFullSize: "Ratio Full Size",
            ratioWidthHeight: "Ratio Width Height",
            format: "Format",
            colorF: "Color F",
            colorB: "Color B",
            lamSqPrice: "Lam Sq Price",
            paperPrice: "Paper Price",
            pressPrice: "Press Price",
            biPrice: "Bi Price",
            dieCut: "Die Cut Price",
            gluding: "Gluding",
            plySet: "Ply Set",
            other: "Other",
            cover: "Cover",
            remark: "Remark",
            ctpPrice: "CTP Price",
            waste: "Waste",
            abbb: "1 (AB)2 (BB)",
            lamination: "Lamination",
            total: "Total",
            firstAccordianHead: "First Accordian Head",
            getStarted: "Get Started",

            // package
            package: "Package",

            //title
            shoppingCartTitle: "Shopping Cart",
            shoppingCartStep1: "Shopping Cart",
            shoppingCartStep2: "Shipping",
            shoppingCartStep3: "Payment Details",
            qty: "Qty",
            jobName: "Job Name",
            editOrder: "Edit Order",
            subTotal: "Sub Total",
            continue: "Continue",
            continueShopping: "Continue Shopping",
            shippingPrice: "Shipping Price",
            shippingNotice: "Note: The shipping fee must be paid by the customers themselves",

            shippingAddress: "Shipping Address",
            shippingMethod: "Shipping Method",
            shipping: "Shipping",
            pickUpFOC: "Pickup (FOC)",
            billingAddress: "Billing Address",

            //order page
            ScanQR: "Scan the QR Code to Make Payment",
            receivedOrder: "Your Order has been received.",
            pending: "Pending",
            order: "Order",
            orderTime: "Order Time",
            totalAmount: "Total Amount", 
            orderAlert: "Oops ... Your order is pending confirmation. Please check your payment details.",
            paymentMethodInstruction: "Payment Method / Instructions",
            paymentTime: "Payment Time",
            paymentMethod: "Payment Method",
            paymentID: "Payment ID",
            ExpiryDate: "Expire Date",
            myOrder: "My Order",

            designImage: "Design File",
            paymentImage: "Upload Kpay Payment Image",
        },
    },
    // myanamr
    myan: {
        message: {
            // header
            home: 'ပင်မစာမျက်နှာ',
            product: 'ထုတ်ကုန်များ',
            aboutus: `စီစီပုံနှိပ်တိုက်အကြောင်း`,
            contactus: 'ဆက်သွယ်ရန်',
            article: 'ဆောင်းပါးများ',
            shoppingcart: 'Shopping Cart',
            search: 'ရှာပါ',
            requestAQuota: 'စျေးနှုန်းတောင်းခံရန်',

            //register
            registerTitle: 'စျေးဝယ်သူအသစ် မှတ်ပုံတင်ရန် ',
            requiredInfo: 'လိုအပ်သည့်သတင်းအချက်အလက်',
            firstName: "First Name",
            lastName: "Last Name",
            address: "အိမ်အမှတ်၊ အလွှာ၊ လမ်း ဖြည့်ရန်",
            password: "စကားဝှက်",
            confirmPassword: "စကားဝှက်ကိုအတည်ပြုပါ",

            // login
            forgetPassword: "စကားဝှက်ကိုမေ့နေပါသလား?",
            changePassword: "စကားဝှက်ကိုပြောင်းလိုပါသလား?",
            updatePassword: "သင့်စကားဝှက်ကို အပ်ဒိတ်လုပ်လိုပါသလား။",


            //contact us
            formMsg: "ကျေးဇူးပြု၍ အောက်ပါအချက်အလက်များကို ဖြည့်ပါ။",
            company: "ကုမ္ပဏီ",
            fullName: "နာမည်အပြည့်အစုံ",
            email: "အီးမေးလ် လိပ်စာ",
            phone: "ဖုန်း",
            department: "ဌာန",
            orderNo: "အမှာစာနံပါတ်",
            msg: "မက်ဆေ့ခ်ျ",
            submit: "တင်သွင်းပါ",
            reset: "ပြန်လည်စတင်ရန်",

            //product
            priceCalculator: "စျေးနှုန်းတွက်ချက်မှု",
            size: "အရွယ်အစား",
            quantity: "အရေအတွက်",
            material: "စက္ကူအမျိုးအစား",
            printingType: "ပုံနှိပ်အမျိုးအစား",
            printingSides: "တစ်ဖက်လှ / နှစ်ဖက်လှ",
            biType: "Bi Type",
            sheet: "စာရွက်အရေအတွက်",
            type: "အထူအပါး",
            ratioFullSize: "Ratio Full Size",
            ratioWidthHeight: "Ratio Width Height",
            format: "Format",
            colorF: "Color F",
            colorB: "Color B",
            lamSqPrice: "ပလတ်စတစ်လောင်း ကျသင့်ငွေ",
            paperPrice: "စက္ကူစျေး",
            pressPrice: "စာရွက်ချုပ် စျေး",
            biPrice: "ပုံနှိပ်ရိုက်စျေး",
            dieCut: "စာရွက်ဖြတ်စျေး",
            gluding: "ကော်",
            plySet: "Ply Set",
            other: "တခြားကျသင့်ငွေ",
            cover: "မျက်နှာဖုံး",
            remark: "မှတ်ချက်",
            ctpPrice: "CTP Price",
            waste: "Waste",
            abbb: "1 (AB)2 (BB)",

            lamination: "Lamination (ပလတ်စတစ်လောင်းခြင်း)",
            total: "စုစုပေါင်း",
            firstAccordianHead: "ပစ္စည်းပို့ဆောင်ခကုန်ကျစရိတ် တွက်ချက်ရန်",
            getStarted: "ဝယ်ယူရန် စတင်ပါ။",

            //checkout
            shoppingCartTitle: "စျေးဝယ်တွန်းလှည်း",
            shoppingCartStep1: "စျေးဝယ်တွန်းလှည်း",
            shoppingCartStep2: "ကုန်ပစ္စည်းပို့ဆောင်ခြင်း",
            shoppingCartStep3: "ငွေပေးချေမှုအသေးစိတ်",
            qty: "အရေအတွက်",
            jobName: "အလုပ်အမည်",
            editOrder: "အော်ဒါကိုပြင်ရန်",
            subTotal: "စုစုပေါင်း",
            continue: "ဆက်လုပ်ရန်",
            continueShopping: "ထပ်၀ယ်ရန်",
            shippingPrice: "ပစ္စည်းပို့ဆောင်မှုစျေးနှုန်း",
            shippingNotice: "မှတ်ချက်း ပို့ခကို customers များမှ မိမိဘာသာကျခံရမည် ဖြစ်သည်",

            //package
            package: "ပက်ကေ့များ",

            shippingAddress: "ကုန်ပစ္စည်းပို့ဆောင်ရမည့်လိပ်စာအသေးစိတ်",
            shippingMethod: "ပို့ဆောင်မှုနည်းလမ်း",
            shipping: "အရောက်ပို့ဆောင်ခြင်း",
            pickUpFOC: "သတ်မှတ်နေရာတွင်လာရွေးယူခြင်း (FOC)",
            billingAddress: "ငွေတောင်းခံလွှာပို့ရန်လိပ်စာ",
            
            //order page
            ScanQR: "QR Scan ဖြင့်ငွေပေးချေပါ",
            receivedOrder: "သင်၏အော်ဒါအားလက်ခံရရှိပါသည်။",
            pending: "စောင့်ဆိုင်းဆဲ",
            order: "လုပ်ငန်းစဉ်အမှတ်",
            orderTime: "လုပ်ငန်းစတင်ချိန်",
            totalAmount: "စုစုပေါင်း ကျသင့်ငွေ",
            orderAlert: "သင်၏အော်ဒါအားစိစစ်နေဆဲပါ။ ကျေးဇူးပြု၍ ငွေပေးချေမှုအား စောင့်ဆိုင်းပေးပါ။",
            paymentMethodInstruction: "ငွေပေးချေမှု/လုပ်ငန်းစဉ်",
            paymentTime: "ငွေပေးချေသည့်နေ့ရက်",
            paymentMethod: "ငွေပေးချေမှုစနစ်",
            paymentID: "ငွေလွှဲID",
            ExpiryDate: "သတ်မှတ်ရက်",
            myOrder: "ကျနော့် Order",

            designImage: "ဒီဇိုင်းဖိုင်",
            paymentImage: "Kpay payment screenshoot ကို တင်ပေးပါ",
        },
    },
}

export default messages;
